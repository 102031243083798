@font-face {
    font-family: "New Spirit";
    src: url("./NewSpirit.otf") format("opentype");
  }
  
  @font-face {
    font-family: "Fira Sans";
    font-weight: 500;
    src: url("./FiraSans-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Fira Sans";
    font-weight: 400;
    src: url("./FiraSans-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Fira Sans";
    font-weight: 300;
    src: url("./FiraSans-Light.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Fira Sans";
    font-style: italic;
    src: url("./FiraSans-Italic.ttf") format("truetype");
  }
  